import React, { useEffect, useReducer } from "react"
/** @jsx jsx */
import Amplify from "@aws-amplify/core";
import Analytics from '@aws-amplify/analytics';
import { API, graphqlOperation, Cache/*Hub, Logger*/ } from "aws-amplify"

import { getAssetDeltaObject, getLegacyShotwireAsset, assetDeltaUpdate } from "../graphql/videoPlayerQueries"
import { getShotwireAssetsDeltaPublic  } from "../graphql/shotassetQueries"
import { getShotwireAssetsDelta } from "../graphql/queries"

import Helmet from 'react-helmet'

import {loadStripe} from '@stripe/stripe-js';

import { Box, jsx } from 'theme-ui'
import { Link, navigate } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import styled from '@emotion/styled'
import InfoCard from '../@geocine/gatsby-theme-organization/components/InfoCard'
import Layout from '../@geocine/gatsby-theme-organization/components/Layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faDownload, faCartPlus, faCartArrowDown, faHome, faEnvelope, faPhone, faPlay, faPause, faSpinner, faVideoSlash, faHeart, faStar, faPiggyBank, faPlus, faMinus, faCheck, faCoins, faLink } from "@fortawesome/free-solid-svg-icons";

import ReactPlayer from 'react-player'
// import ReactJWPlayer from 'react-jw-player';
import ReactJWPlayer from '../@geocine/gatsby-theme-organization/components/JwVideoPlayer'

// import Layout from "../components/layout"
// import SEO from "../components/seo"
import CoinbaseCommerceButton from 'react-coinbase-commerce';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';

import 'src/css/theme.scss'
import favicon from '../@geocine/gatsby-theme-organization/assets/favicon.ico'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import awsconfig from "../aws-exports";
import { AppsContext } from '../AppsProvider'

// import { Authenticator, SignIn, SignUp, ConfirmSignUp, Greetings } from 'aws-amplify-react';


Amplify.configure(awsconfig);
// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false

// const analyticsConfig = {
//   AWSPinpoint: {
//         // Amazon Pinpoint App Client ID
//         appId: '12039a8ccae04f7fab5cbff6eb095368',
//         // Amazon service region
//         region: 'us-east-1',
//         mandatorySignIn: false,
//   }
// }

// Analytics.configure(analyticsConfig)

var stripePromise, checkoutSessionId

const WatermarkLogoDiv = styled('div')`
  position: absolute;
  top: 20px;
  left: 35px;
  opacity: 0.6;
  display:none;
`
 
const UserOpsDiv = styled('div')`
  
  top: 10px;
  left: 0px;
  
`

// const MySectionHeader = Object.assign({}, AmplifyTheme.sectionHeader, { background: 'orange' });
// const MyTheme = Object.assign({}, AmplifyTheme, { sectionHeader: MySectionHeader });
const MyTheme = {
    signInButtonIcon: { 'display': 'none' },
    googleSignInButton: { 'backgroundColor': 'red', 'borderColor': 'red' },
    button: { 'backgroundColor': 'red', 'borderColor': 'red' },
    navButton: { 'backgroundColor': 'red', 'borderColor': 'red' }


}

const MetaEditable = ['caption', 'headline', 'credit', 'byline', 'keywords', 'suppCat', 'city', 'state', 'country']

const categoryMap = {
  CelebrityCandids:'Celebrity Candids',
  RedCarpetEvents:'Red Carpet',
  News:'News',
  Sports: 'Sports',
  Music: 'Music',
  Fashion: 'Fashion',
  Travel: 'Travel',
  Arts: 'Arts',
  Nature: 'Nature',
  Aerial: 'Aerial',
  CelebrityInterviews:'Celebrity Interviews'
}

const formArchivedMap = {
  "false":'Not Published / Archived',
  "true":'Published / Archived ',
}

const formMarketplaceMap = {
  "false":'Private',
  "true":'Licensing Available',
}

var setupElements = async function() {
  stripePromise = await loadStripe("pk_live_xVFKV4FGy3ycA15Ft5vPBZDl");
};


const XeroApp = () => {
  // console.log('location:', location)
  let userInfo = null, 
      uRoles = null, 
      uGroups = null, 
      metaForm = {}, 
      searchParams, 
      fileSize, 
      srcMediainfo, 
      mediainfo = null, 
      updateMediainfo = false, 
      cart = [],
      myDownloads = [],
      favs = [],
      modifiedThumbPlyr = false
  // console.log('location.state:',location.state)
    // console.log('location.search:',location.search)

  // let playerDiv = React.createRef()
   
  // const [state, dispatch] = useReducer(reducer, initialState);
  const appsContext = React.useContext(AppsContext)
  
  let vPlayer = appsContext.state.videoPlayer 
  // console.log('vPlayer:', vPlayer)

  // myDownloads = Cache.getItem('myDownloads')
  // cart = Cache.getItem('shopCart')
  const expiration = new Date(2020, 12, 18)

  const todayDate = new Date()
  const nowYr = todayDate.getFullYear()
  const nowMo = todayDate.getMonth()+1
  const nowDay = todayDate.getDate()
  
  // console.log('yyyy, mo, dd', nowYr ,  nowMo+1 , nowDay)

  // if(appsContext.state.user){
  //   const  userInfo  = appsContext.state.user
  //   // console.log('userInfo: ', userInfo)

  //   const uRoles = userInfo.attributes[`custom:roles`]
  //   // console.log('uRoles: ', uRoles)
  // }


// const downloadRandomImage = () => {
//   fetch('http://localhost:8080/api/files')
//     .then(response => {
//       const filename =  response.headers.get('Content-Disposition').split('filename=')[1];
//       response.blob().then(blob => {
//         let url = window.URL.createObjectURL(blob);
//         let a = document.createElement('a');
//         a.href = url;
//         a.download = filename;
//         a.click();
//     });
//  });
// }
  const handleOnchange = (e) => {
    // console.log('e.target.id:',e.target.id)
    // console.log('e.target.value:',e.target.value)
    if(e.target.id==='setDateYear'){
      // console.log('year')
      // console.log('e.target.value:',e.target.value)
      appsContext.dispatch({type:'METAFORM', metaForm: { ...appsContext.state.metaForm, yr: e.target.value} })
    }else if(e.target.id==='setDateMonth'){
      // console.log('month')
      // console.log('e.target.value:',e.target.value)
      appsContext.dispatch({type:'METAFORM', metaForm: { ...appsContext.state.metaForm, mo: e.target.value} })
    }else if(e.target.id==='setDateDay'){
      // console.log('day')
      // console.log('e.target.value:',e.target.value)
      appsContext.dispatch({type:'METAFORM', metaForm: {...appsContext.state.metaForm, day: e.target.value} })
    }else if(e.target.id==='category'){
      // console.log('e.target.value:',e.target.value)
      appsContext.dispatch({type:'METAFORM', metaForm: {...appsContext.state.metaForm, category: e.target.value} })
    }else if(e.target.id==='archived'){
      // console.log('e.target.value:',e.target.value)
      appsContext.dispatch({type:'METAFORM', metaForm: {...appsContext.state.metaForm, archived: e.target.value} })
    }else if(e.target.id==='marketplace'){
      // console.log('e.target.value:',e.target.value)
      appsContext.dispatch({type:'METAFORM', metaForm: {...appsContext.state.metaForm, marketplace: e.target.value} })
    }


  }

  const handleMetaChange = (e) => {
    // console.log('e.target.id:',e.target.id)
    // console.log('e.target.value:',e.target.value)
    // console.log('metaField:',metaField)
    
    // const metaForm = {}
    // metaForm[e.target.id] = e.target.value
    // console.log('metaForm::', metaForm)
    appsContext.dispatch({type:'METAFORM', metaForm: {...appsContext.state.metaForm, [e.target.id]:e.target.value} })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.persist()
    appsContext.dispatch({ type: "METAUPDATE", isMetaSaving: true })
    // console.log('Submit target:',e.target)
    // console.log('Submit target.category:',e.target.headline.value)
    // console.log('appsContext.state.videoPlayer.assetObj:', appsContext.state.videoPlayer.assetObj)
    // console.log('appsContext.state.videoPlayer.assetObj.srcMediainfo:', appsContext.state.videoPlayer.assetObj.srcMediainfo)

    const assetObj = {
      id: appsContext.state.videoPlayer.assetObj.id,
      archived: e.target.archived.value,
      category: e.target.category.value,
      setDate: e.target.setDateYear.value + e.target.setDateMonth.value + e.target.setDateDay.value,
      headline: e.target.headline.value,
      caption: e.target.caption.value,
      byline: e.target.byline.value,
      keywords: e.target.keywords.value,
      suppCat: e.target.suppCat.value,
      city: e.target.city.value,
      country: (typeof e.target.country !== 'undefined') ? e.target.country.value : '' ,
      marketplace: e.target.marketplace.value,
      state: (typeof e.target.state !== 'undefined') ? e.target.state.value : '',
    }

    // console.log('appsContext.state.videoPlayer.assetObj.video_length: ', appsContext.state.videoPlayer.assetObj.video_length)

    if(appsContext.state.videoPlayer.assetObj.srcMediainfo === null){
        // console.log('retrieve & update video info...')
          // appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: true })
              // console.log('appsContext.state.videoPlayer: ', appsContext.state.videoPlayer.assetObj.fileName)
              // return filename
              const OgSrcVideoJobInfo = await API.get('shotwireAPI', '/createVideoProxy', {
                              'queryStringParameters': {
                                // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                                // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                                // 'setName': appsContext.state.videoPlayer.assetObj.name,
                                // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
                                'jobAction': 'OgSrcVideoJobInfo',
                                // 's3srcUrl' : srcObj.srcUrl,
                                'ogSrcFile' : appsContext.state.videoPlayer.assetObj.fileName,
                                // 'jobTemplate': 'Shotwire-Custom-Job-User-'+ appsContext.state.user.username//'Shotwire-Watermarked-Proxies'                      
                              }
                            });
              // console.log('OgSrcVideoJobInfo:', JSON.stringify(OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo) )
              // console.log('OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo:', JSON.parse(OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo) )
              mediainfo = JSON.parse(OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo)
              // console.log('mediainfo:', mediainfo )
              // return OgSrcVideoJobInfo.OgSrcVideoJobInfo
              if(OgSrcVideoJobInfo.OgSrcVideoJobInfo.length>0){                            
           
                  assetObj.srcMediainfo= OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo
                  updateMediainfo = true
                  
              }
      
    }else if(!(appsContext.state.videoPlayer.assetObj.video_length>0)){
          // console.log('update video info...')

            mediainfo = JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo)
            // console.log('mediainfo:', mediainfo )
            updateMediainfo = true
          


    }

    if(updateMediainfo===true){
        assetObj.video_length= mediainfo.container.duration/1000
        assetObj.fileSize= mediainfo.container.fileSize/1024/1024
        assetObj.video_width= mediainfo.video[0].width
        assetObj.video_height= mediainfo.video[0].height
        assetObj.video_aspect= mediainfo.video[0].aspectRatio
        assetObj.video_frames= mediainfo.video[0].frameCount
          // video_format: String,
        assetObj.video_bitrate= mediainfo.video[0].bitrate
        assetObj.video_fps= mediainfo.video[0].framerate 
        
        if(typeof mediainfo.audio !== 'undefined'){
          assetObj.audio_codec= mediainfo.audio[0].codec
          // audio_format: mediainfo.audio[0].format,
          assetObj.audio_bitrate= mediainfo.audio[0].bitrate
          assetObj.audio_rate= mediainfo.audio[0].samplingRate
          assetObj.audio_length= mediainfo.audio[0].duration
        }
    }


    const eventDate = new Date(e.target.setDateYear.value +'-'+ e.target.setDateMonth.value  +'-'+ e.target.setDateDay.value).getTime()
    // console.log('eventDate:', eventDate)
    // console.log('assetObj:', assetObj)
  if(typeof e.target.forceUnpublish != 'undefined'){
    // console.log('e.target.forceUnpublish.value:',e.target.forceUnpublish.value)
    if(e.target.forceUnpublish.value == 'on'){
      // console.log('updating asset.')
      updateAssetDelta(assetObj)
    }else{
      // console('abort save')
    }
   
  }else{
    // console.log('updating asset..')
    updateAssetDelta(assetObj)
  }
  
    
    

  }

  const handleClick = async (e, arg) => {
    
    if (e){ 
      e.preventDefault();      

      if(arg === 'addToCart'){
          // console.log('add to cart...')
          cart = Cache.getItem('shotwire-shopCart')
          // console.log('cart:', cart)

          const expiration = new Date(2020, 12, 18)
        
        if(cart == null){
          // console.log('set item...')
          Cache.setItem('shotwire-shopCart', [ appsContext.state.videoPlayer.assetObj.id ], { expires: expiration.getTime() });
          appsContext.dispatch({type:"SHOPCART", shopCart:[ appsContext.state.videoPlayer.assetObj.id ]})
          // document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.assetId).style.color = "red";
        }else{
          
          if(!cart.includes(appsContext.state.videoPlayer.assetObj.id)){
            cart.push(appsContext.state.videoPlayer.assetObj.id)
            // e.target.style.color = 'red'
            // document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.id).style.color = "red";
            // document.getElementById("fav_"+assetId).style.backgroundColor = "lightblue";
            Cache.setItem('shotwire-shopCart', cart, { expires: expiration.getTime() })
            appsContext.dispatch({type:"SHOPCART", shopCart:cart})           
            // console.log('added to shopCart.')
            // console.log('cart.', cart)
          }else{
            // e.target.style.color = 'white'
            // document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.id).style.color = "white";
            // document.getElementById("fav_"+assetId).style.backgroundColor = "pink";
            const tmp = cart.filter(item => item !== appsContext.state.videoPlayer.assetObj.id)
            // console.log('tmp:', tmp)
            Cache.setItem('shotwire-shopCart', tmp, { expires: expiration.getTime() })
            cart = Cache.getItem('shotwire-shopCart')
            appsContext.dispatch({type:"SHOPCART", shopCart:cart})
            // console.log('removed from shopCart.')
            // console.log('cart.:', cart)
         }
       }
          // navigate(
          //     "/",
          //     // {
          //     //   state: { searchform },
          //     // }
          //   )
      }else if(arg === 'showEmbedModal'){
        // event.preventDefault();
        // console.log('props.location.pathname:', props.location.pathname)
          // console.log('clicked menu link:', event.target)
          // currentCategory = category
          // appsContext.dispatch({ type: "NEWCATEGORY",  currentCategory});
          // console.log('embedModalShow.', appsContext.state.embedModalShow)
          Analytics.record({
                  name: 'EMBED', 
                  // Attribute values must be strings
                  attributes: { 
                    // username: userInfo.username,
                    // userSub: userInfo.attributes[`sub`],
                    id: appsContext.state.videoPlayer.assetObj.id,
                    video: appsContext.state.videoPlayer.assetObj.name,  
                    // highRezFileName: appsContext.state.videoPlayer.assetObj.highRezFileName, 
                    // setId: appsContext.state.videoPlayer.assetObj.realSetId,
                    // setDate: appsContext.state.videoPlayer.assetObj.setDate,
                    // signedUrl: appsContext.state.videoPlayer.assetObj,
                    // thumbNailUrl: appsContext.state.videoPlayer.assetObj.thumbNailUrl
                  }
                });
          appsContext.dispatch({type:"SHOWEMBEDMODAL", embedModalShow:true})
      }else if(arg === 'showDownloadsModal'){
        // event.preventDefault();
        // console.log('props.location.pathname:', props.location.pathname)
          // console.log('clicked menu link:', event.target)
          // currentCategory = category
          // appsContext.dispatch({ type: "NEWCATEGORY",  currentCategory});
          // console.log('downloadsModalShow.', appsContext.state.downloadsModalShow)
          Analytics.record({
                  name: 'DOWNLOAD', 
                  // Attribute values must be strings
                  attributes: { 
                    // username: userInfo.username,
                    // userSub: userInfo.attributes[`sub`],
                    id: appsContext.state.videoPlayer.assetObj.id,
                    video: appsContext.state.videoPlayer.assetObj.name,  
                    // highRezFileName: appsContext.state.videoPlayer.assetObj.highRezFileName, 
                    // setId: appsContext.state.videoPlayer.assetObj.realSetId,
                    // setDate: appsContext.state.videoPlayer.assetObj.setDate,
                    // signedUrl: appsContext.state.videoPlayer.assetObj,
                    // thumbNailUrl: appsContext.state.videoPlayer.assetObj.thumbNailUrl
                  }
                });
          appsContext.dispatch({type:"SHOWDOWNLOADSMODAL", downloadsModalShow:true})
      }else if(arg === 'buySubscription'){
        // event.preventDefault();
        // console.log('props.location.pathname:', props.location.pathname)
          // console.log('clicked menu link:', event.target)
          // currentCategory = category
          // appsContext.dispatch({ type: "NEWCATEGORY",  currentCategory});
          // console.log('buysub.', appsContext.state.buySubsciptionModalShow)
          appsContext.dispatch({type:"SHOWBUYSUBMODAL", buySubsciptionModalShow:true})
      }else if(arg === 'videoProblem'){
        // event.preventDefault();
        // console.log('props.location.pathname:', props.location.pathname)
          // console.log('clicked menu link:', event.target)
          // currentCategory = category
          // appsContext.dispatch({ type: "NEWCATEGORY",  currentCategory});
      }else if(arg === 'addFavorite'){
        let favs = Cache.getItem('shotwire-favorites')

        // console.log('favs:', favs)
        const expiration = new Date(2020, 12, 18)
        
        if(favs == null){
          // console.log('set item...')
          Cache.setItem('shotwire-favorites', [ appsContext.state.videoPlayer.assetObj.id ], { expires: expiration.getTime() });
          appsContext.dispatch({type:"FAVORITES", favorites:[ appsContext.state.videoPlayer.assetObj.id ]})
          // document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.assetId).style.color = "red";
        }else{
          
          if(favs.includes(appsContext.state.videoPlayer.assetObj.id)===false){
            favs.push(appsContext.state.videoPlayer.assetObj.id)
            // e.target.style.color = 'red'
            // document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.id).style.color = "red";
            // document.getElementById("fav_"+assetId).style.backgroundColor = "lightblue";
            Cache.setItem('shotwire-favorites', favs, { expires: expiration.getTime() })
            appsContext.dispatch({type:"FAVORITES", favorites:favs})           
            // console.log('added to favs.')
            // console.log('favs.', favs)
          }else{
            // e.target.style.color = 'white'
            // document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.id).style.color = "white";
            // document.getElementById("fav_"+assetId).style.backgroundColor = "pink";
            const tmp = favs.filter(item => item !== appsContext.state.videoPlayer.assetObj.id)
            // console.log('tmp:', tmp)
            Cache.setItem('shotwire-favorites', tmp, { expires: expiration.getTime() })
            favs = Cache.getItem('shotwire-favorites')
            appsContext.dispatch({type:"FAVORITES", favorites:favs})
            // console.log('removed from favs.')
            // console.log('favs.:', favs)
         }
       }
       // console.log('appsContext.state.favorites:', appsContext.state.favorites)
       // console.log('favs:.', favs)
        
      }else if(arg === 'metaSubmit'){
        // console.log('metaSubmit target:',e.target)
      }else if(arg === 'grabFrame'){
        // console.log('grabFrame')
        // const frame = captureVideoFrame(this.player.getInternalPlayer())
      }else if(arg === 'VideoSubscriptions'){
        // console.log('video subscriptions.')
         // currentCategory = category
          // console.log('currentCategory:', currentCategory)
          // appsContext.dispatch({ type: "NEWCATEGORY",  currentCategory});
          // appsContext.dispatch({ type: "PAGETOKENS", nextToken: null })
        
          navigate(
              '/VideoSubscriptions',
              // {
              //   state: { searchform },
              // }
            )

          Analytics.record({
                  name: 'VIDEOSUBSCRIPTIONVIEW', 
                  // Attribute values must be strings
                  attributes: { 
                    id: appsContext.state.videoPlayer.assetObj.id,
                    video: appsContext.state.videoPlayer.assetObj.name,  
                    // highRezFileName: appsContext.state.videoPlayer.assetObj.highRezFileName, 
                    // setId: appsContext.state.videoPlayer.assetObj.realSetId,
                    // setDate: appsContext.state.videoPlayer.assetObj.setDate,
                    // signedUrl: appsContext.state.videoPlayer.assetObj,
                    // thumbNailUrl: appsContext.state.videoPlayer.assetObj.thumbNailUrl
                  }
                });
          

      }else if(arg === 'updateSrcMediaInfo'){
          e.preventDefault()

          // console.log('check status key, fileName:', key , appsContext.state.proxyList[key].fileName)
          try{
              appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: true })
              // console.log('appsContext.state.videoPlayer: ', appsContext.state.videoPlayer.assetObj.fileName)
              // return filename
              const OgSrcVideoJobInfo = await API.get('shotwireAPI', '/createVideoProxy', {
                              'queryStringParameters': {
                                // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                                // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                                // 'setName': appsContext.state.videoPlayer.assetObj.name,
                                // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
                                'jobAction': 'OgSrcVideoJobInfo',
                                // 's3srcUrl' : srcObj.srcUrl,
                                'ogSrcFile' : appsContext.state.videoPlayer.assetObj.fileName,
                                // 'jobTemplate': 'Shotwire-Custom-Job-User-'+ appsContext.state.user.username//'Shotwire-Watermarked-Proxies'                      
                              }
                            });
              // console.log('OgSrcVideoJobInfo:', JSON.stringify(OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo) )
              // console.log('OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo:', JSON.parse(OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo) )
              const mediainfo = JSON.parse(OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo)
              // console.log('mediainfo:', mediainfo )
              // return OgSrcVideoJobInfo.OgSrcVideoJobInfo
              if(OgSrcVideoJobInfo.OgSrcVideoJobInfo.length>0){                            
                  
                  const assetObj = {
                    id: appsContext.state.videoPlayer.assetObj.id,
                    srcMediainfo: OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo,
                    video_length: mediainfo.container.duration/1000,
                    fileSize: mediainfo.container.fileSize/1024/1024,
                    video_width: mediainfo.video[0].width,
                    video_height: mediainfo.video[0].height,
                    video_aspect: mediainfo.video[0].aspectRatio,
                    video_frames: mediainfo.video[0].frameCount,
                    // video_format: String,
                    video_bitrate: mediainfo.video[0].bitrate,
                    video_fps: mediainfo.video[0].framerate ,
                    audio_codec: mediainfo.audio[0].codec,
                    // audio_format: mediainfo.audio[0].format,
                    audio_bitrate: mediainfo.audio[0].bitrate,
                    audio_rate: mediainfo.audio[0].samplingRate,
                    audio_length: mediainfo.audio[0].duration,



                  }
                  // console.log('input assetObj:', assetObj)
                  if(typeof assetObj.srcMediainfo !== 'undefined'){
                      // console.log('updating asset, ...')
                     updateAssetDelta(assetObj)
                   }
              }

              // add video to delta table once proxy is created
              // appsContext.dispatch({type:"CREATEVIDEOPROXY", newAsset:{ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' } })
            // appsContext.addAssetDelta({ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' })
           
              
            }catch(err){
              //add an image placehold for assets with no image
              // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
              // console.log('media info error: ', err)
            }
      }
    }
  }

  const handleDownload = (e) => {
    e.preventDefault()
      // if (state === 'signedIn') {
          /* Do something when the user has signed-in */
          try{
            // getVideoUrl('hrdl')
            // vPlayer.isDownloading = true
            // appsContext.dispatch({ type: "VPLAYER", vPlayer });

            //Record an event
            // Analytics.record('some-event-name');

            //Record a custom event
            // Analytics.record({
            //     name: 'Video-Download',
            //     attributes: { file: appsContext.state.videoPlayer.assetObj.highRezFileName, name: appsContext.state.videoPlayer.assetObj.name }
            // });
          
            // appsContext.dispatch({ type: "SRC", signedUrl: signedUrl.videoUrl });
// console.log('caching download stats...')
            Analytics.record({
                  name: 'DOWNLOAD', 
                  // Attribute values must be strings
                  attributes: { 
                    username: userInfo.username,
                    userSub: userInfo.attributes[`sub`],
                    id: appsContext.state.videoPlayer.assetObj.id,
                    video: appsContext.state.videoPlayer.assetObj.name,  
                    // highRezFileName: appsContext.state.videoPlayer.assetObj.highRezFileName, 
                    // setId: appsContext.state.videoPlayer.assetObj.realSetId,
                    // setDate: appsContext.state.videoPlayer.assetObj.setDate,
                    // signedUrl: appsContext.state.videoPlayer.assetObj,
                    // thumbNailUrl: appsContext.state.videoPlayer.assetObj.thumbNailUrl
                  }
                });
  
            if(myDownloads == null){
              // console.log('set item...')
              Cache.setItem('shotwire-myDownloads', [ appsContext.state.videoPlayer.assetObj.id ], { expires: expiration.getTime() });
              appsContext.dispatch({type:"MYDOWNLOADS", myDownloads:[ appsContext.state.videoPlayer.assetObj.id ]})
            }else{
              
              if(!myDownloads.includes(appsContext.state.videoPlayer.assetObj.id)){
                myDownloads.push(appsContext.state.videoPlayer.assetObj.id)
                // e.target.style.color = 'red'
                // document.getElementById("fav_"+assetId).style.color = "red";
                // document.getElementById("fav_"+assetId).style.backgroundColor = "lightblue";
                Cache.setItem('shotwire-myDownloads', myDownloads, { expires: expiration.getTime() })
                appsContext.dispatch({type:"MYDOWNLOADS", myDownloads:myDownloads})           
                // console.log('added to favs.')
                // console.log('favs.', favs)
              }else{
                // e.target.style.color = 'white'
                // document.getElementById("fav_"+assetId).style.color = "white";
                // document.getElementById("fav_"+assetId).style.backgroundColor = "pink";
                const tmp = myDownloads.filter(item => item !== appsContext.state.videoPlayer.assetObj.id)
                // console.log('tmp:', tmp)
                Cache.setItem('shotwire-myDownloads', tmp, { expires: expiration.getTime() })
                myDownloads = Cache.getItem('shotwire-myDownloads')
                appsContext.dispatch({type:"MYDOWNLOADS", myDownloads:myDownloads})
                // console.log('removed from favs.')
                // console.log('favs.:', favs)
             }
              
            }
            //*/

          }catch(err){
            
            // console.log('error: ', err)
          }

      // }else if(state === 'signIn'){

      // }
  }


  const handlePlayerRef = () => {
    // e.preventDefault()
    console.log('handlePlayerRef')
    // console.log('video node:',playerDiv.current.querySelector('video'))
        // console.log('video node:',playerDiv.current)

    // setState({ playing: true })
    // vPlayer.playing = true
    // appsContext.dispatch({ type: "VPLAYER",  vPlayer });
  }

  // const handlePlay = (e) => {
  //   e.preventDefault()
  //   console.log('onPlay')
  //   // setState({ playing: true })
  //   vPlayer.playing = true
  //   appsContext.dispatch({ type: "VPLAYER",  vPlayer });
  // }

  // const handlePause = (e) => {
  //   if (e){ 
  //     e.preventDefault()
  //     console.log('onPause')
  //     // setState({ playing: false })
  //     vPlayer.playing = false
  //     appsContext.dispatch({ type: "VPLAYER",  vPlayer });
  //       // event.preventDefault();
  //       // console.log('props.location.pathname:', props.location.pathname)
  //         // console.log('clicked menu link:', event.target)
  //         // currentCategory = category
  //         // appsContext.dispatch({ type: "VPLAYER",  currentCategory});
  //     }
  // }

  // const handlePlayPause = (e) => {
  //   e.preventDefault()
  //   vPlayer.playing = !appsContext.state.vplayer.playing
  //   appsContext.dispatch({ type: "VPLAYER",  vPlayer });
  //   // setState({ playing: !this.state.playing })
  // }

  // const handleStop = (e) => {
  //   e.preventDefault()
  //   // setState({ url: null, playing: false })
  //   vPlayer.playing = false
  //   appsContext.dispatch({ type: "VPLAYER",  vPlayer });
  // }
 

// const getAssetDeltaObject = `query GetAssetMetaPhoto($id: String!) {
//     getShotwireAssetsDelta(id: $id){
//       id
//       archived
//       realSetId
//       name
//       highRezFileName
//       setDate
//       category
//       uploader    
//       caption
//       headline
//       byline
//       keywords
//       city
//       suppCat
//       thumbNailUrl
//       mp4Urls      
//       dashUrl
//     }
//   }`

  async function assetDeltaObject(id){
    // console.log('get asset id:', id)
    // console.log('user:', appsContext.state.user)
    // console.log('appsContext.state.isAdmin:', appsContext.state.isAdmin)
    // console.log('appsContext.state.isUploader:', appsContext.state.isUploader)
    try{

        const assetObj = await API.graphql(
            {
                // query: getShotwireAssetsDelta,
                query: getShotwireAssetsDeltaPublic,
                variables: { id: id },
                authMode:  (appsContext.state.isAdmin===true || appsContext.state.isUploader===true || appsContext.state.user!==null)  ? 'AMAZON_COGNITO_USER_POOLS': 'AWS_IAM'
              }
            );
      

        // console.log('assetObj::', assetObj.data.getShotwireAssetsDelta)
      //               console.log('vPlayer:',vPlayer)
        const tmp = vPlayer
        //update vplayer
        // vPlayer.signedUrl=''
        // tmp.image = assetObj.data.getShotwireAssetObject.thumbNailUrl
        tmp.assetObj = assetObj.data.getShotwireAssetsDelta
        // vPlayer.playing=false
        // vPlayer.isDownloading=0
        // vPlayer.hrDlSrc= ''
        appsContext.dispatch({ type: "VPLAYER", vPlayer: tmp });

    }catch(err){
      // console.log('assetDeltaObject err', err)
    }
    
  }

  async function shotwireAssetObject(params){

    try{
    const assetObj = await API.graphql(graphqlOperation(getLegacyShotwireAsset, {realSetId: params.arsid, name:params.name } ))
    // console.log('assetObj::', assetObj)
    // console.log('assetObj=>>', assetObj.data.getLegacyShotwireAsset.items)
    //               console.log('vPlayer:',vPlayer)
                  const tmp = vPlayer
                  //update vplayer
                  // vPlayer.signedUrl=''
                  // tmp.image = assetObj.data.getShotwireAssetObject.thumbNailUrl
                  tmp.assetObj = assetObj.data.getLegacyShotwireAsset.items[0]
                  // vPlayer.playing=false
                  // vPlayer.isDownloading=0
                  // vPlayer.hrDlSrc= ''
                  appsContext.dispatch({ type: "VPLAYER", vPlayer: tmp });
                }catch(err){
                  // console.log('err:',err)
                }
  }


 


  async function updateAssetDelta(assetObj) {
    try{
      // console.log('date:', assetObj.setDate)
      if(typeof assetObj.setDate !== 'undefined'){
            const formattedDate = [assetObj.setDate.slice(0, 4), assetObj.setDate.slice(4,6), assetObj.setDate.slice(6,8)].join('-')
            // console.log('formattedDate:', formattedDate)
            const eventDate = new Date(formattedDate).getTime()
            // console.log('eventDate:',eventDate)
          }
      // const asset = {...assetObj, archived: 1, category: '', timestamp: eventDate } 
      const asset = {...assetObj }      
     
      Object.keys(asset).forEach((key) => (asset[key] == null||asset[key] == '') && delete asset[key]);
      // console.log('asset->', asset)
      if(asset.category=='SELECT A CATEGORY' && asset.archived==1){
        // console.log('abort, category required to archive.')
        appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition: '1500px', title:"Error", body: 'A CATEGORY Must be selected to Publish a video.' }})
      }else{

        if(asset.archived==0 || asset.archived=="0" || asset.archived==="false" || asset.archived===false){
          asset.category = "Ingest"
        }
        // console.log('asset=>', asset)
        const savemeta = await API.graphql(graphqlOperation(assetDeltaUpdate, { input: asset }));
        // console.log('savemeta:', savemeta)
        appsContext.dispatch({ type: "METAUPDATE", isMetaSaving: false })
        appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: false })

      }
      
    } catch(err) {
      appsContext.dispatch({ type: "METAUPDATE", isMetaSaving: false })
      appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: false })
      // console.log('delta err:',err)
    }    
  }

  function DownloadsModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Shotwire Video Download Options:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Your Account Options:</h4>
          <p>
            Download Options Here
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function EmbedModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Shotwire Video Embed
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Embed Options:</h4>
          <p>
            Embed Code here
            
            Thumbnail URL: {appsContext.state.videoPlayer.assetObj.thumbNailUrl}
            
            {<iframe src="https://video2020.shotwire.com/VideoPlayerB/?aid={appsContext.state.videoPlayer.assetObj.id}" width="640" height="360" frameBorder="none" scrolling="no" marginWidth="0"></iframe> 
              }          
            </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

useEffect(() => {
      // console.log('get for user product and stats...')
      let isMounted = true
      if(isMounted === true){
        // console.log('setting up user...')

          if(appsContext.state.user){
            const  userInfo  = appsContext.state.user
            // console.log('userInfo: ', userInfo)

            if(typeof userInfo.signInUserSession.idToken.payload[`cognito:groups`] != 'undefined'){
              uGroups = userInfo.signInUserSession.idToken.payload[`cognito:groups`]
              // console.log('uGroups:',uGroups)
              // console.log('uRoles: ', uRoles)
              if( uGroups.some(r => ['Admins'].indexOf(r) >= 0) ){
                // console.log('Admin user.')
                 appsContext.dispatch({ type: "ISADMIN", isAdmin:true }) 
               }
              if( uGroups.some(r => ['Uploaders'].indexOf(r) >= 0) ){
                // console.log('Uploader user.')
                 appsContext.dispatch({ type: "ISUPLOADER", isUploader:true }) 
               }
              // console.log('isAdmin:', isAdmin)
            }

         
          }
        }

        return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  isMounted = false
                  
                };

  },[ ])

  useEffect(() => {
          let isMounted = true
            
          if(isMounted === true){
            // console.log('mounted...')
             // setupElements();
             // cart = Cache.getItem('shotwire-shopCart')
             // appsContext.dispatch({type:"SHOPCART", shopCart:cart}) 
             // favs = Cache.getItem('shotwire-favorites')
             // appsContext.dispatch({type:"FAVORITES", favorites:favs})
             // myDownloads = Cache.getItem('myDownloads')
           
               
              // if(appsContext.state.user){
              //   userInfo  = appsContext.state.user
              //     // console.log('userInfo: ', userInfo)

              //     if(typeof userInfo.signInUserSession.idToken.payload[`cognito:groups`] != 'undefined'){
              //       uGroups = userInfo.signInUserSession.idToken.payload[`cognito:groups`]
              //       // console.log('uGroups:',uGroups)
              //       // console.log('uRoles: ', uRoles)
              //       if( uGroups.some(r => ['Admins'].indexOf(r) >= 0) ){
              //         // console.log('Admin user.')
              //          appsContext.dispatch({ type: "ISADMIN", isAdmin:true }) 
              //        }
              //       if( uGroups.some(r => ['Uploaders'].indexOf(r) >= 0) ){
              //         // console.log('Uploader user.')
              //          appsContext.dispatch({ type: "ISUPLOADER", isUploader:true }) 
              //        }
              //       // console.log('isAdmin:', isAdmin)
              //     }                      
              // }
              // console.log('userInfo:', userInfo)
              // console.log('video asset:', appsContext.state.videoPlayer)

             
            }
               // console.log('video player:', appsContext.state.videoPlayer)
              


              

             

              if(appsContext.state.isAdmin){
                // subscribe to asset meta updates to show changes in the video details? or just update from the meta form?
                // or just dispatch a call to get the asset data?
              }


                return () => {
                  // console.log('video player unmounting...')
                  isMounted = false
                  appsContext.dispatch({type:'METAFORM', metaForm:{counter:0}})
               
                };
          }, [  ]);

  async function getVideoUrl(dlType) {
    


      try{
        // console.log('appsContext.state.videoPlayer.assetObj:', appsContext.state.videoPlayer.assetObj)

        // const eventDate = new Date(formattedDate).getTime()
        
        // console.log('hrDlSrc: ',appsContext.state.videoPlayer.hrDlSrc)
        const signedUrl = ''
        let tmp = {}
        /* This is for legacy pcn videos only
        const signedUrl = await API.get('pcnvideoUrls', '/videos', {
                        'queryStringParameters': {
                          'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                          'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                          'setName': appsContext.state.videoPlayer.assetObj.name,
                          'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),

                        }
                      });
                      //*/
      // const albumData = await API.graphql(graphqlOperation(ListAllAssets,{coId:"6B3E1A00BDAB94E4DA166A30A759D44C"}));
        // console.log('signedUrl:', signedUrl)

        // dispatch({ type: "SRC", signedUrl: signedUrl.videoUrl });
        // videoPlayer.signedUrl = signedUrl.videoUrl 
        //   image: location.state.image,
        //   assetObj: location.state.assetObj
        // }
        if(dlType === 'hrdl'){
              // console.log('GET HRDL...')
              // console.log('appsContext.state.videoPlayer.hrDlSrc-> ',appsContext.state.videoPlayer.hrDlSrc)
              // console.log('hrdl url:', signedUrl.videoUrl)
              // console.log('hr file:', assetObj.highRezFileName)

              // fetch(signedUrl.videoUrl)
              //     .then(response => {
              //       const filename =  location.state.assetObj.highRezFileName
              //       response.blob().then(blob => {
              //         let url = window.URL.createObjectURL(blob);
              //         let a = document.createElement('a');
              //         a.href = url;
              //         a.download = filename;
              //         a.click();
              //     });
              //  });

              // vPlayer.isDownloading = false
              if(appsContext.state.user){


                // console.log('appsContext.state.videoPlayer.assetObj.mp4Urls:',appsContext.state.videoPlayer.assetObj.mp4Urls[0])
                // console.log('signedUrl.videoUrl-:', signedUrl.videoUrl)
                // vPlayer.hrDlSrc = (appsContext.state.user) ? signedUrl.videoUrl : ''

                if((appsContext.state.videoPlayer.assetObj.mp4Urls!=null)){
                  
                  tmp.hrDlSrc = appsContext.state.videoPlayer.assetObj.mp4Urls[0].replace(/\{|\}/g,'').substr(2)  
/*
async getDataFetch(){
    const response =
      await fetch("https://dog.ceo/api/breeds/list/all",
        { headers: {'Content-Type': 'application/json'}}
      )
    console.log(await response.json())
}

                  var params = {
                      Bucket: "examplebucket", 
                      Key: "HappyFace.jpg"
                     };
                     s3.headObject(params, function(err, data) {
                       if (err) console.log(err, err.stack); // an error occurred
                       else     console.log(data);           // successful response
                       
                       data = {
                        AcceptRanges: "bytes", 
                        ContentLength: 3191, 
                        ContentType: "image/jpeg", 
                        ETag: "\"6805f2cfc46c0f04559748bb039d69ae\"", 
                        LastModified: <Date Representation>, 
                        Metadata: {
                        }, 
                        VersionId: "null"
                       }
                       
                     });             
   */               
                }else{

                  tmp.hrDlSrc = signedUrl.videoUrl 
                }


              }else tmp.hrDlSrc = ''
                // console.log('getVideoUrl dispatching...',appsContext.state.videoPlayer, tmp)
              appsContext.dispatch({ type: "VPLAYER", vPlayer:{...appsContext.state.videoPlayer,...tmp, fileSize:fileSize} })
        }
        else{
          
            // console.log('signedUrl:', signedUrl)

            // if((typeof appsContext.state.videoPlayer.assetObj.mp4Urls!='undefined')){
            //     //add array of src videos
            //     vPlayer.srcUrls={[
            //       {src: appsContext.state.videoPlayer.assetObj.hlsUrl, type: 'video/ogg'},
            //       {src: appsContext.state.videoPlayer.assetObj.mp4Urls[0], type: 'video/mp4'}
            //       {src: appsContext.state.videoPlayer.assetObj.dashUrl, type: 'video/mp4'},
                                  
            //     ]}
            // }
            

           appsContext.dispatch({ type: "SRC", signedUrl: signedUrl.videoUrl })
           // console.log('appsContext.state.signedUrl:', appsContext.state.signedUrl)
           // Record a custom event
            // Analytics.record({
            //     name: `Asset-Viewed`,
            //     attributes: { user: appsContext.state.user.username, name: appsContext.state.videoPlayer.assetObj.name }
            // })
       }
        
      }catch(err){
        //add an image placehold for assets with no image
        // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
        console.log('error: ', err)
      }
      

  }

   async function createCheckoutSession(planId) { 
    let apiName = 'shotwireAPI'; // replace this with your api name.
    let path = '/create-checkout-session'; //replace this with the path you have configured on your API
    let myInit = {
                    body: {
                      planId: planId
                    }, // replace this with attributes you need
                    headers: {} // OPTIONAL e.g. "Content-Type": "application/json"
                 }

    await API.post(apiName, path, myInit).then(response => {
      // Add your code here
      // console.log('response:',response)
      return response.checkoutSessionId;
    }).then(sessId => {
        // Add your code here
        // console.log('sessId:',sessId)
        checkoutSessionId = sessId;

        stripePromise
          .redirectToCheckout({
            sessionId: sessId
          })
          .then(function(result) {
            // console.log('stripe result:', result)
            if(result.error){
              console.log('error:', result.error.message)
              appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition: '1500px', title:"Purchase Error", body: result.error.message }})
            }
            // console.log("error");
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          })
          .catch(function(err) {
            // console.log('stripe err:',err);
          });


    }).catch(error => {
        // console.log('sess error:',error)
    });
    // return sess
}

  async function captureVideoFrame(video, format, quality) {
        if (typeof video === 'string') {
            video = document.getElementById(video);
        }

        format = format || 'jpeg';
        quality = quality || 0.92;

        if (!video || (format !== 'png' && format !== 'jpeg')) {
            return false;
        }

        var canvas = document.createElement("CANVAS");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        canvas.getContext('2d').drawImage(video, 0, 0);

        var dataUri = canvas.toDataURL('image/' + format, quality);
        var data = dataUri.split(',')[1];
        var mimeType = dataUri.split(';')[0].slice(5)

        var bytes = window.atob(data);
        var buf = new ArrayBuffer(bytes.length);
        var arr = new Uint8Array(buf);

        for (var i = 0; i < bytes.length; i++) {
            arr[i] = bytes.charCodeAt(i);
        }

        var blob = new Blob([ arr ], { type: mimeType });
        return { blob: blob, dataUri: dataUri, format: format };
    };

  const handleThumbnail = (e, action) => {
    e.preventDefault()
    // console.log('e.target', e.target)
    // console.log('key:', key)
    // console.log('action:', action)
    // console.log('asset:', appsContext.state.proxyList[key].thumbNailUrl)
    let count = 0, go = false

    if(action === 'save'){
      //update the assets new thumbnailurl to the db
      const assetObj = {
                            id: appsContext.state.videoPlayer.assetObj.id,
                            thumbNailUrl: appsContext.state.videoPlayer.assetObj.thumbNailUrl
                          }
      // console.log('update assetObj:', assetObj)
      updateAssetDelta(assetObj)

      
        // modifiedThumbPlyr = appsContext.state.modifiedThumbPlyr
        modifiedThumbPlyr = false
        appsContext.dispatch({ type: "MODIFIEDTHUMBPLAYR", modifiedThumbPlyr })
      
      

    }

    count = Math.round(appsContext.state.videoPlayer.assetObj.thumbNailUrl.substr(-7,7).replace(/(\.jpg)/,'')) 
    // console.log('count:' , count)
    if(action === 'minus'){
       
      if(count>0){
         count--
         go = true
      }
        
   }
    if(action === 'plus'){
       
      if(count<14){
        count++
        go = true
      }
      

    }

  
    if(go === true){
        let aObj = appsContext.state.videoPlayer.assetObj
        aObj.thumbNailUrl = appsContext.state.videoPlayer.assetObj.thumbNailUrl.replace(/(\d\d\d\.jpg)/, ("00" + count).slice(-3) + '.jpg' )
        // console.log('aObj:', aObj)
        // console.log('appsContext.state.videoPlayer:', appsContext.state.videoPlayer)
        // appsContext.dispatch({ type: "ARCHIVELIST", archiveListConnection: {items: aList, archiveListNextToken: null} });
        appsContext.dispatch({ type:'VPLAYER',  vPlayer:{...appsContext.state.videoPlayer, assetObj:aObj, image:aObj.thumbNailUrl} } )
        modifiedThumbPlyr = true
        appsContext.dispatch({ type: "MODIFIEDTHUMBPLAYR", modifiedThumbPlyr })
    }

// console.log('modifiedThumbPlyr:',modifiedThumbPlyr)
   
  }

  async function getVideoThumbnails(thumbNailUrl){
    try{
        
        // console.log('filename: ', filename)
        // return filename
        const videoThumbs = await API.get('shotwireAPI', '/createVideoProxy', {
                        'queryStringParameters': {
                          // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                          // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                          // 'setName': appsContext.state.videoPlayer.assetObj.name,
                          // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
                          'jobAction': 'getVideoThumbnails',
                          // 's3srcUrl' : srcObj.srcUrl,
                          'thumbNailUrl' : thumbNailUrl,
                          // 'jobTemplate': 'Shotwire-Custom-Job-User-'+ appsContext.state.user.username//'Shotwire-Watermarked-Proxies'                      
                        }
                      });
        // console.log('videoThumbs:', videoThumbs)
        return videoThumbs
        

        // add video to delta table once proxy is created
        // appsContext.dispatch({type:"CREATEVIDEOPROXY", newAsset:{ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' } })
      // appsContext.addAssetDelta({ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' })
     
        
      }catch(err){
        //add an image placehold for assets with no image
        // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
        console.log('error: ', err)
      }
  }

  // image={
  //                   (asset.thumbNailUrl!=null)?asset.thumbNailUrl:(
  //                                       (asset.assetId)?
  //                                       `https://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/${asset.assetId}.jpg`
  //                                       :`https://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/undefined.jpg`)

  // `https://s3.amazonaws.com/assets.shotloadr.com/9824537_17189PCNV_KevinHartCrashSite001.mp4`

  //settting auth state to 'loading' hide the default signin form
if (appsContext.state) {
// console.log('appsContext.state.videoPlayer.assetObj: ',appsContext.state.videoPlayer.assetObj)
  if(appsContext.state.videoPlayer.assetObj){
    // console.log('video page!')
     return(
          <Layout>
           <Helmet>
              <title>Shotwire Video - {(appsContext.state.videoPlayer.assetObj.suppCat===null)?'':appsContext.state.videoPlayer.assetObj.suppCat}</title>
              <link rel="icon" href={favicon} type="image/x-icon" />
            </Helmet>
          <Container>
          {
// <Row>
//  <Col sm={12} lg={12} className="d-md-none d-lg-none  d-xl-none" style={{textAlign:"center"}}>
 
//   <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=mostwishforitem&banner=00TMGBBBQ7NZSRVZZX02&f=ifr&linkID=d4e67873703495aac2575c8df9edfcf0&t=shotwire-20&tracking_id=shotwire-20" width="320" height="50" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe> </Col>
//   <Col sm={12} lg={12} className="d-none d-md-block d-lg-block d-xl-block">
//    <div sx={{
//     paddingTop:'15px',
//     width:'1024px',
//     height:'100px',
//     textAlign:'left'
//     // border:'1px solid white'
//     // paddingLeft: '10px',
//     // paddingTop: '20px'
//    }}>

//   <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primemain&banner=1FM3MKXZNJ0PPEBRV2R2&f=ifr&linkID=97f0c247004f7548309281f40eeb7940&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
//    </div>
//   </Col>
//  </Row>
}
 
 {
  // <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primemusic&banner=1RY2Z0V0AAZV4RSZEXG2&f=ifr&linkID=58e39d79d4e3b1cbad1bc8f565011769&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>

  // <iframe 
  // src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=amazonhomepage&f=ifr&linkID=8355df7f0e378d27c9867426a3cc6c5a&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" 
  // style={{border:"none;"}} frameborder="0"></iframe>
 // <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primemain&banner=17T0MQR5WKGJCZNRHCG2&f=ifr&linkID=4fd690c31f8d7d5bd0c3ba76772d8945&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
 // <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primemain&banner=1FM3MKXZNJ0PPEBRV2R2&f=ifr&linkID=97f0c247004f7548309281f40eeb7940&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>

 }




          { 
          //   <Authenticator 
          //   errorMessage={ errMap }
          //   authState='loading'
          //   hideDefault={ true } 
          //   onStateChange={ handleAuthStateChange } 
          //   theme={ MyTheme }>                   
          //   <Greetings/>
          //   <AlwaysOn/>
          //   <SignIn/>
          //   <SignUp/>
          //   <ConfirmSignUp/>
          // </Authenticator>
        }
        <Row>
          <div sx={{
             
              // paddingTop:'15px',
  padding: '25px',
  // width:'1024px',
  // height:'100px',
  // border:'1px solid white'
  // paddingLeft: '30px',
          }}>
          <h3>Xero</h3>
          </div>
          </Row>
          </Container>
          <Container>
            <Row>
              <Col sm={12} lg={8}>              
                <div 
                // ref={playerDiv} 
                sx={{
                    padding: '10px',
                    paddingBottom: '30px',
                // backgroundColor: 'blue',
                width: '100%',
                height: 'auto'
                // objectFit: 'contain'
                      }}>
         
         {
            //below is test vmap response from DFP
          // https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=vmap&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ar%3Dpremidpost&cmsid=496&vid=short_onecue&correlator=[avail.random]
          
          //GPT - google publisher tag
          // https://pubads.g.doubleclick.net/gampad/ads?iu=/5756600/Shotwire_Video/Shotwire_Video_Preroll&description_url=http%3A%2F%2Fvideo2020.shotwire.com%2FVideoPlayer&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=
         
          //below is the non-IMP vmap vast gpt
          // https://pubads.g.doubleclick.net/gampad/ads?iu=/5756600/Shotwire_Video/Shotwire_Video_Preroll&description_url=http%3A%2F%2Fvideo2020.shotwire.com%2FVideoPlayer&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&correlator=12345&vpmute=0&vpa=0&vad_format=linear&url=http%3A%2F%2Fvideo2020.shotwire.com%2FVideoPlayer&vpos=preroll
          
          // https://pubads.g.doubleclick.net/gampad/ads?iu=/5756600/Shotwire_Video/Shotwire_Video_Preroll&description_url=http%3A%2F%2Fvideo2020.shotwire.com%2FVideoPlayer&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&correlator=888&vpmute=0&vpa=0&vad_format=linear&url=http%3A%2F%2Fvideo2020.shotwire.com%2FVideoPlayer&vpos=preroll
          // https://pubads.g.doubleclick.net/gampad/ads?iu=/5756600/Shotwire_Video/808&description_url=http%3A%2F%2Fvideo2020.shotwire.com%2FVideoPlayer&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&correlator=[placeholder]&vpmute=0&vpa=0&vad_format=linear&url=http%3A%2F%2Fvideo2020.shotwire.com%2FVideoPlayer&vpos=postroll
          // <ReactPlayer 
          //  // url='https://303d2a161de042b0ac88abd5b29f9a62.mediatailor.us-west-2.amazonaws.com/v1/master/8a4ba88babff65dd0d996eb7f32ee91abd60f3bb/MyTestCampaign/master.m3u8' 
          //  // url='https://d7byjd2oa0k4h.cloudfront.net/976b8046-40b0-42ea-b217-b06d5fc61f29/hls/7546f748-255d-4daa-bdb8-6f1e664dc0c0_16995PCNVEX_HaileyClauson_TMF.m3u8'
          //  // url='https://c309f9429b864350a6566e8538888329.mediatailor.us-west-2.amazonaws.com/v1/master/8a4ba88babff65dd0d996eb7f32ee91abd60f3bb/ShotwireVideoTest/7546f748-255d-4daa-bdb8-6f1e664dc0c0_16995PCNVEX_HaileyClauson_TMF.m3u8'
          //  // url='https://e981e1badb6d4eba88f752f9bd486457.mediatailor.us-west-2.amazonaws.com/v1/master/8a4ba88babff65dd0d996eb7f32ee91abd60f3bb/TestShotwire/5e2b5526-5fe9-45c6-886e-b6d17e665f5d_Warren+G_DVD_NTSC_Lg_720.m3u8'
          //   url='https://c309f9429b864350a6566e8538888329.mediatailor.us-west-2.amazonaws.com/v1/master/8a4ba88babff65dd0d996eb7f32ee91abd60f3bb/ShotwireVideoTest/b12a1c0a-56bd-4d3a-9ef9-318ae77144fd_Diversity_Awards_WS.m3u8'
            
          //   // url='https://d7byjd2oa0k4h.cloudfront.net/92ca2a53-a669-43a0-9143-a11914768242/hls/b12a1c0a-56bd-4d3a-9ef9-318ae77144fd_Diversity_Awards_WS.m3u8'
          //   width='100%' 
          //   controls 
          //   light={ encodeURI(appsContext.state.videoPlayer.assetObj.thumbNailUrl) } 
          //   config={{ file: { attributes: { controlsList: 'nodownload', style: { width: '100%', height: 'auto' }}} }}
          // />
       
         }

         {
          // (appsContext.state.videoPlayer.assetObj.thumbNailUrl!=null) ?     
          //       (  
          //        <ReactPlayer 
          //         // ref={() => player}
          //         // ref={handlePlayerRef()} 
          //         // onReady={() => handlePlayerRef()}
          //         // onReady={() => console.log(player.getInternalPlayer())}
          //         // url={ encodeURI(appsContext.state.videoPlayer.assetObj.mp4Urls[0].replace(/^(\{S=)|\}/g,'')) } //mp4 urls, need new way of playng these... need to select by file name or tag
          //         url={ ( typeof appsContext.state.videoPlayer.assetObj.cmafHlsUrl !== "undefined") 
          //               ? (appsContext.state.videoPlayer.assetObj.cmafHlsUrl !== null) ? 
          //               encodeURI(appsContext.state.videoPlayer.assetObj.cmafHlsUrl): (appsContext.state.videoPlayer.assetObj.cmafDashUrl !== null) ? 
          //               encodeURI(appsContext.state.videoPlayer.assetObj.cmafDashUrl): encodeURI(appsContext.state.videoPlayer.assetObj.hlsUrl) 
          //                 : encodeURI(appsContext.state.videoPlayer.assetObj.hlsUrl) } //hls
          //         width='100%' 
          //         controls 
          //         light={ encodeURI(appsContext.state.videoPlayer.assetObj.thumbNailUrl) } 
          //         config={{ file: { attributes: { controlsList: 'nodownload', style: { width: '100%', height: 'auto' }}} }} 
          //         />
          //       )
          //   // (appsContext.state.videoPlayer.assetObj.hlsUrl!=null) ?     
          //   //     (  
          //   //      <ReactPlayer url={ appsContext.state.videoPlayer.assetObj.hlsUrl } width='100%' controls light={ appsContext.state.videoPlayer.image } config={{ file: { attributes: { controlsList: 'nodownload', style: { width: '100%', height: 'auto' } }}}} />
          //   //     )
          //   // .react-player__preview { background-size: 100% !important; } where to put this style? for cover image bg
          //     :

          //       ( appsContext.state.signedUrl ?

          //         <ReactPlayer url={ appsContext.state.signedUrl } width='100%' controls light={ appsContext.state.videoPlayer.image ? encodeURI(appsContext.state.videoPlayer.image) : `https://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/${appsContext.state.videoPlayer.assetObj.assetId}.jpg` } config={{ file: { attributes: { controlsList: 'nodownload', style: { width: '100%', height: 'auto' } }}}} />
          //       :
          //         <Spinner animation="border" variant="secondary" />

          //       )
          } 
          {
            //react JW Player
            // <ReactJWPlayer

            // />
          }         
                </div>

               <WatermarkLogoDiv>
                  <Image
                    src={require(`../@geocine/gatsby-theme-organization/assets/shotwire-logo.png`)}
                    alt="Shotwire Video"
                    height={58}
                    width={121}
                    rounded
                  />
                </WatermarkLogoDiv>
                <div sx={{padding:'10px'}}>
                {
                  // <Button  sx={{ marginBottom: '2px' }} variant="dark" href="#!" size="md" onClick={() => {
                  //                   const frame = captureVideoFrame(playerDiv.current.querySelector('video').getInternalPlayer())
                  //                   console.log('captured frame', frame)
                  //                   // this.setState({ image: frame.dataUri })
                  //                 }}>Capture Frame</Button>
                                }
                 {               
                                 // <Button sx={{ marginBottom: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleClick(e, 'videoProblem')}>
                                 //         <FontAwesomeIcon
                                 //           icon={faVideoSlash}
                                 //           size="lg"
                                 //           sx={{
                                 //             // marginRight: '0.5rem'
                                 //           }}
                                 //         />{''}
                                         
                                 //       </Button>
                 }

                </div>
{
                   // <Row>
                   //  <Col sm={12} lg={12} className="d-md-none d-lg-none  d-xl-none" style={{textAlign:'center'}}>
                    
                   //   <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=amazonhomepage&f=ifr&linkID=71d3093c671662bd39eab6a15e1dd5e3&t=shotwire-20&tracking_id=shotwire-20" width="320" height="50" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>  
                   //   </Col>
                   //   <Col sm={12} lg={12} className="d-none d-md-block d-lg-block d-xl-block">
                   //    <div sx={{
                   //     paddingTop:'15px',
                   //     width:'1024px',
                   //     height:'100px',
                   //     textAlign:'left'
                   //     // border:'1px solid white'
                   //     // paddingLeft: '10px',
                   //     // paddingTop: '20px'
                   //    }}>
   
                   //   <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=camera&banner=1NA6DKA4BJJ7G5WRRJG2&f=ifr&linkID=7d7eab64c3e09ca67cb82b1cc163e07f&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
                   //    </div>
                   //   </Col>
                   //  </Row>
                }   
                { 
                //   (appsContext.state.isAdmin===true || appsContext.state.isEditor===true || (appsContext.state.videoPlayer.assetObj.uploader ===  ( appsContext.state.user!==null ? appsContext.state.user.username:null )  ) ) ? 

                //   <ButtonGroup aria-label="Thumbnail">
                //   <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" disabled>Thumbnail</Button>
                //   <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleThumbnail(e, 'minus')}>
                // <FontAwesomeIcon
                //             icon={faMinus}
                //             size="sm"
                //             sx={{
                //               // marginRight: '0.1rem'
                //             }}
                //           />
                // </Button>
                // <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleThumbnail(e, 'plus')}>
                // <FontAwesomeIcon
                //             icon={faPlus}
                //             size="sm"
                //             sx={{
                //               // marginRight: '0.1rem'
                //             }}
                //           />
                // </Button>
                // {
                //   <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleThumbnail(e, 'save')} disabled={!appsContext.state.modifiedThumbPlyr}>
                //   <FontAwesomeIcon
                //               icon={faCheck}
                //               size="sm"
                //               sx={{
                //                 // marginRight: '0.1rem'
                //               }}
                //             />
                //   </Button>
                // }
                // </ButtonGroup>
                // :<span></span>

                }

                <div>
                <br />
                <h6 className="text-uppercase mb-4 font-weight-bold">Text</h6>
                   
                </div>
                <div>
 
 </div>
              </Col>
              <Col sm={12} lg={4}>
              
                  
                    <div sx={{
                        width: '100%',
                        padding: '10px',
                        // backgroundColor: '#444'
                      }}
                      >
                   
                       <h4>Xero Test</h4>
                   
                    
                    
                    <b>Company:</b> {
                      (typeof appsContext.state.videoPlayer.assetObj.name != 'undefined') ?
                      // appsContext.state.videoPlayer.assetObj.name.substr(appsContext.state.videoPlayer.assetObj.name.indexOf('_')+1)
                      appsContext.state.videoPlayer.assetObj.name
                      : ''
                    }
                    <div sx={{
                            paddingTop: '0.5rem'
                          }}>
{ 
  // (appsContext.state.videoPlayer.hrDlSrc === '' || typeof appsContext.state.videoPlayer.hrDlSrc === 'undefined') ? 
  (appsContext.state.isAdmin===true || appsContext.state.isEditor===true || (appsContext.state.videoPlayer.assetObj.uploader ===  ( appsContext.state.user!==null ? appsContext.state.user.username:null )  ) ) ?

        (

        <div>
        <Button 
          sx={{ marginBottom: '2px' }} 
          variant="dark" size="md" 
          // href={ appsContext.state.videoPlayer.hrDlSrc } 
          onClick={e => handleClick(e, 'showDownloadsModal')}
        >
                        <FontAwesomeIcon
                          icon={faDownload}
                          size="lg"
                          sx={{
                            marginRight: '0.5rem'
                          }}
                        />{' '}
                        Download                        
                        { 
                          // Math.round(appsContext.state.videoPlayer.fileSize) 
                        }
                         
                      </Button>
                      <br />
      <Button 
          sx={{ marginBottom: '2px' }} 
          variant="dark" size="md" 
          // href={ appsContext.state.videoPlayer.hrDlSrc } 
          onClick={e => handleClick(e, 'showEmbedModal')}
        >
                      <FontAwesomeIcon
                          icon={faLink}
                          size="lg"
                          sx={{
                            marginRight: '0.5rem'
                          }}
                        />{' '}Embed 
                        </Button>
          </div>
          
        )
           :

        (
          <div sx={{
            marginBottom: '2px'
           }} >
           <Button variant="dark" size="md">Sign up or Log in to Download or Embed this Video</Button>
          </div>

        )
    }
    {' '}
    <Button sx={{ marginBottom: '2px' }} variant="dark" href="" size="md" onClick={e => handleClick(e, 'addFavorite')}>
                        {
                         appsContext.state.favorites!==null ? (appsContext.state.favorites.includes(appsContext.state.videoPlayer.assetObj.id)===true) ?
                        <FontAwesomeIcon
                          id={'fav_'+ appsContext.state.videoPlayer.assetObj.assetId}
                          icon={faStar}
                          size="lg"
                          sx={{
                            color:'gold'                            
                          }}                        
                        />:
                        <FontAwesomeIcon
                          id={'fav_'+ appsContext.state.videoPlayer.assetObj.assetId}
                          icon={faStar}
                          size="lg"
                          sx={{
                            color:'white'                            
                          }}                        
                        />:
                        <FontAwesomeIcon
                          id={'fav_'+ appsContext.state.videoPlayer.assetObj.assetId}
                          icon={faStar}
                          size="lg"
                          sx={{
                            color:'white'                            
                          }}                        
                        />

                        } Add to favorites
                      </Button>
                      {' '}
                      <br />
                      <Button sx={{ marginBottom: '2px' }} variant="dark" href="" size="md" onClick={e => handleClick(e, 'addToCart')}>
                        {
                           appsContext.state.shopCart!==null ? (!appsContext.state.shopCart.includes(appsContext.state.videoPlayer.assetObj.id)) ?
                                                <span sx={{color: 'white'}}>
                                                <FontAwesomeIcon
                                                  icon={faCartPlus}
                                                  size="lg"
                                                  sx={{
                                                    marginRight: '0.5rem'
                                                  }}
                                                />{' '}
                                                Add to Cart
                                                </span>
                                        :
                                        <span sx={{
                // marginRight: '0.5rem'
                // color: (appsContext.state.favorites.includes(assetObj.id)===true) ? 'gold':'white'
                color: 'gold'
              }}>
                                        <FontAwesomeIcon
                                                  icon={faCartArrowDown}
                                                  size="lg"
                                                  sx={{
                                                    marginRight: '0.5rem'
                                                  }}
                                                />{' '}
                                                In Your Cart
                                                </span> : <span sx={{color: 'white'}}>
                                                <FontAwesomeIcon
                                                  icon={faCartPlus}
                                                  size="lg"
                                                  sx={{
                                                    marginRight: '0.5rem'
                                                  }}
                                                />{' '}
                                                Add to Cart
                                                </span>
                        }
                      </Button>
                      {
                        // <div>
                        //   <a class="buy-with-crypto"
                        //      href="https://commerce.coinbase.com/checkout/7f7adf5b-9989-4bd7-b604-ee5846449208">
                        //     Buy with Crypto
                        //   </a>
                        //   <script src="https://commerce.coinbase.com/v1/checkout.js?version=201807">
                        //   </script>
                        // </div>
                      }
                      {
                        //https://commerce.coinbase.com/checkout/d9c8b11c-7d69-4b32-9aa8-8e13df5653f0 //$200 film-tv license
                        //https://commerce.coinbase.com/checkout/d001ec79-7f09-4882-9df0-ffa25077e946 //$600 game media license
                        }
                       
                     
                       
                      {' '}
                      <Button sx={{ marginTop: '2px',marginBottom: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleClick(e, 'VideoSubscriptions')}>
                        <FontAwesomeIcon
                          icon={faPiggyBank}
                          size="lg"
                          sx={{
                            marginRight: '0.5rem'
                          }}
                         
                        />{' '}
                        Buy Subscription Package
                      </Button>
                      {' '}
                      
</div>
                      <br />
                    <b>Headline:</b> {appsContext.state.videoPlayer.assetObj.headline}<br />
                    <b>Caption:</b> {appsContext.state.videoPlayer.assetObj.caption}<br />
                    <b>Keywords:</b> {appsContext.state.videoPlayer.assetObj.keywords}<br />
                    <b>Byline:</b> {appsContext.state.videoPlayer.assetObj.byline}<br />
                    <br /><br />
                    
                    <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=amzn_biz_bounty&banner=0K3WH19RMBH6B504RGG2&f=ifr&linkID=dbc88890e9a61dac05509ce147c5fb9c&t=shotwire-20&tracking_id=shotwire-20" width="300" height="250" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
                    <br />                   
                    {
                           // (appsContext.state.videoPlayer.assetObj.headline !== null) ? 
                           // ( appsContext.state.videoPlayer.assetObj.headline.match(/(swift)|(taylor)/ig) ) ? <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=primemusic&banner=1AKPX3FSXCJ64S0YZW02&f=ifr&linkID=524fafba6677521334c3dafb6911a210&t=shotwire-20&tracking_id=shotwire-20" width="300" height="250" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
                           //      :<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=musicandentertainmentrot&f=ifr&linkID=6ae6b120e8379331742ac5973a1813ea&t=shotwire-20&tracking_id=shotwire-20" width="300" height="250" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
                    
                           //      :         
                           //      <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=musicandentertainmentrot&f=ifr&linkID=6ae6b120e8379331742ac5973a1813ea&t=shotwire-20&tracking_id=shotwire-20" width="300" height="250" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
                    
                                        
                                        }
                                         {
                                          // <br />
                                          //                                       <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=22&l=ur1&category=beauty&banner=04XCSE6T0AFM7MK75482&f=ifr&linkID=51d8d678fd6eaf62ab27ac5d595c6ce1&t=shotwire-20&tracking_id=shotwire-20" width="250" height="250" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
                                          //                     <br />
                                          //                     <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=42&l=ur1&category=beauty&banner=0BEE8NNQHPRVWHT1G102&f=ifr&linkID=3645c25cbefde5a9bfbd2105667ec435&t=shotwire-20&tracking_id=shotwire-20" width="234" height="60" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
                                                              }
                      <div sx={{
                        width: '100%',
                        padding: '0px',
                        paddingBottom: '20px'
                      }}>


                      
                      { appsContext.state.videoPlayer.isDownloading ? (
          <div sx={{
  paddingRight: '10px',
 }} >
          <FontAwesomeIcon
            icon={faSpinner}
            size="sm"
            sx={{
              // marginRight: '0.5rem'
            }}
            spin
          />
          </div>
        ) : (
          <div sx={{
  padding: '0px',
 }}></div>
        )
    }

    
                      </div>
                    </div>
                  
                  
{
  //metaform here
}
                    {
                     ( false && appsContext.state.isAdmin===true || appsContext.state.isEditor===true || (appsContext.state.videoPlayer.assetObj.uploader ===  ( appsContext.state.user!==null ? appsContext.state.user.username:null )  ) ) ? 
                      (
                        <div sx={{
                          width: '100%',
                          padding: '10px',

                        }}>
                        <h4>Meta / IPTC</h4>
                        {
                        // <MetaEditForm/>
                        }

            <Form sx={{
            padding: '5px'
            }} 
            onSubmit={e => handleSubmit(e)}>
            
            <Form.Row>
            <Form.Group controlId="setDateYear">
              <Form.Label>Created Date (Year </Form.Label>
              <Form.Control as="select" value={(appsContext.state.metaForm.yr!=null)?appsContext.state.metaForm.yr:''}  onChange={e => handleOnchange(e)}>
          
          {
            Array(30).fill().map((_, i) => (

              <option key={2020-i}>{2020-i}</option>

              ))
          }
          
            </Form.Control>
            </Form.Group>

            <Form.Group controlId="setDateMonth">
              <Form.Label>/ Month</Form.Label>
              <Form.Control as="select" value={(appsContext.state.metaForm.mo!=null)?appsContext.state.metaForm.mo:''}  onChange={e => handleOnchange(e)}>
          {
            Array(12).fill().map((_, i) => (

              <option key={("0" + (i+1)).slice(-2)}>{("0" + (i+1)).slice(-2)}</option>

              ))
          
          }
              
            </Form.Control>
            </Form.Group>

            <Form.Group controlId="setDateDay">
              <Form.Label>/ Day )</Form.Label>
              <Form.Control as="select"  value={(appsContext.state.metaForm.day!=null)?appsContext.state.metaForm.day:''}  onChange={e => handleOnchange(e)}>
          {
            Array(31).fill().map((_, i) => (

             <option key={("0" + (i+1)).slice(-2)}>{("0" + (i+1)).slice(-2)}</option>

              ))
          
          }
            </Form.Control>
            </Form.Group>
          </Form.Row>

        <Form.Group controlId="category">
            <Form.Label>Category</Form.Label>
            <Form.Control as="select" value={appsContext.state.metaForm.category} onChange={e => handleOnchange(e)}>
            <option>SELECT A CATEGORY</option>
        {
          Object.keys(categoryMap).map((keyName, i) => (

              <option value={keyName} key={keyName}>{categoryMap[keyName]}</option>

            ))

        }
        </Form.Control>
        </Form.Group>

        <Form.Group controlId="archived">
            <Form.Label>Archived / Published</Form.Label>
            <Form.Control as="select" value={appsContext.state.metaForm.archived} onChange={e => handleOnchange(e)}>
        {
          Object.keys(formArchivedMap).map((keyName, i) => (

              <option value={keyName} key={keyName}>{formArchivedMap[keyName]}</option>

            ))

        }
        </Form.Control>
        </Form.Group>

        <Form.Group controlId="marketplace">
            <Form.Label>Privacy</Form.Label>
            <Form.Control as="select" value={appsContext.state.metaForm.marketplace} onChange={e => handleOnchange(e)}>
        {
          Object.keys(formMarketplaceMap).map((keyName, i) => (

              <option value={keyName} key={keyName}>{formMarketplaceMap[keyName]}</option>

            ))

        }
        </Form.Control>
        </Form.Group>

        {


          Object.keys(appsContext.state.videoPlayer.assetObj).map((keyName, i) => (
            
            (MetaEditable.includes(keyName)) ? 
              <Form.Group controlId={keyName} key={keyName}>
              <Form.Label>
                {keyName.charAt(0).toUpperCase() + 
                   keyName.slice(1)}
              </Form.Label>
           
                <Form.Control as="textarea" placeholder={keyName} value={(appsContext.state.metaForm[keyName]==null)?'':appsContext.state.metaForm[keyName]} onChange={e => handleMetaChange(e)} disabled={appsContext.state.isMetaSaving} rows={(keyName=='caption'||keyName=='headline')?8:1} />
             
              </Form.Group>
            
            :

            <span key={keyName}></span>
            

          ))


        }
        {
          (appsContext.state.metaForm.category!='Ingest' && appsContext.state.metaForm.archived==0)?
          <Form.Group controlId="forceUnpublish">
            <Form.Check type="checkbox" label="Force Un-Publish" />
          </Form.Group>
          :
          <span></span>
        }
          <Button variant="primary" type="submit">
          {
            (appsContext.state.isMetaSaving==true) ? 
                        (<span> Saving Meta <FontAwesomeIcon
                          icon={faSpinner}
                          size="sm"
                          sx={{
                            // marginRight: '0.5rem'
                          }}
                          spin
                        /> </span>)
                        : 
                        ('Submit')
                      }
          </Button>
          </Form> 

<br />
<br />
          <h5>Source Media Info</h5>

           { ( appsContext.state.videoPlayer.assetObj.srcMediainfo !== null && typeof appsContext.state.videoPlayer.assetObj.srcMediainfo !== 'undefined') ?
            <span>
              <br />
            <h6>File Container</h6>
        {


          Object.keys(JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo).container).map((keyName, i) => (
            
            // (MetaEditable.includes(keyName)) ? 
            //   <Form.Group controlId={keyName} key={keyName}>
            //   <Form.Label>
            //     {keyName.charAt(0).toUpperCase() + 
            //        keyName.slice(1)}
            //   </Form.Label>
           
            //     <Form.Control as="textarea" placeholder={keyName} value={(appsContext.state.metaForm[keyName]==null)?'':appsContext.state.metaForm[keyName]} onChange={e => handleMetaChange(e)} disabled={appsContext.state.isMetaSaving} rows={(keyName=='caption'||keyName=='headline')?8:1} />
             
            //   </Form.Group>
            
            // :

            <div key={keyName}>
            <i>{keyName}:</i> {JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo).container[keyName]}
            </div>
            

          ))


        }
        <br />
          <h6>Video</h6>
        {


          Object.keys(JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo).video[0]).map((keyName, i) => (
            
            // (MetaEditable.includes(keyName)) ? 
            //   <Form.Group controlId={keyName} key={keyName}>
            //   <Form.Label>
            //     {keyName.charAt(0).toUpperCase() + 
            //        keyName.slice(1)}
            //   </Form.Label>
           
            //     <Form.Control as="textarea" placeholder={keyName} value={(appsContext.state.metaForm[keyName]==null)?'':appsContext.state.metaForm[keyName]} onChange={e => handleMetaChange(e)} disabled={appsContext.state.isMetaSaving} rows={(keyName=='caption'||keyName=='headline')?8:1} />
             
            //   </Form.Group>
            
            // :

            <div key={keyName}>
            <i>{keyName}:</i> {JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo).video[0][keyName]}
            </div>
            

          ))


        }
        <br />
          <h6>Audio</h6>
       
        {

          (typeof JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo).audio !== 'undefined') ?

          (
            Object.keys(JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo).audio[0]).map((keyName, i) => (
                      
                      // (MetaEditable.includes(keyName)) ? 
                      //   <Form.Group controlId={keyName} key={keyName}>
                      //   <Form.Label>
                      //     {keyName.charAt(0).toUpperCase() + 
                      //        keyName.slice(1)}
                      //   </Form.Label>
                     
                      //     <Form.Control as="textarea" placeholder={keyName} value={(appsContext.state.metaForm[keyName]==null)?'':appsContext.state.metaForm[keyName]} onChange={e => handleMetaChange(e)} disabled={appsContext.state.isMetaSaving} rows={(keyName=='caption'||keyName=='headline')?8:1} />
                       
                      //   </Form.Group>
                      
                      // :
          
                      <div key={keyName}>
                      <i>{keyName}:</i> {JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo).audio[0][keyName]}
                      </div>
                      
          
                    ))
            )
          : 'hello'


        }

            </span>
            :
           <Button variant="dark" href="#!" size="md" onClick={e => handleClick(e, 'updateSrcMediaInfo')}>
            {
              (appsContext.state.isSrcMediaInfoUpdating===true) ? 
                          (<span> Updating Source Media Info <FontAwesomeIcon
                            icon={faSpinner}
                            size="sm"
                            sx={{
                              // marginRight: '0.5rem'
                            }}
                            spin
                          /> </span>)
                          : 
                          ('Update Source Media Info')
                        }
            </Button>
            }

                        </div>
                      )
                      :
                        <span></span>
                    }


                  

                  

              </Col>
            </Row>
          </Container>
        {
          // works in localhost dev, but breaks in production deployment. dont know why
        <DownloadsModal
                    show={appsContext.state.downloadsModalShow}
                    onHide={() => appsContext.dispatch({type:"SHOWDOWNLOADSMODAL",downloadsModalShow:false})}
                  />
              }

              {
          // works in localhost dev, but breaks in production deployment. dont know why
        <EmbedModal
                    show={appsContext.state.embedModalShow}
                    onHide={() => appsContext.dispatch({type:"SHOWEMBEDMODAL",embedModalShow:false})}
                  />
              }
           
           </Layout>
           )

       
         
        
  }else{
    return(
          <Layout>
          <Container>

          { 
          <h3>No Video Info</h3> 
        }
          <div sx={{
  padding: '10px',
 }} >
 <Spinner animation="border" variant="secondary" />
        {  
          // <FontAwesomeIcon
          //   icon={faSpinner}
          //   size="2x"
          //   sx={{
          //     // marginRight: '0.5rem'
          //   }}
          //   spin
          // />
        }
          </div>
          </Container>
          </Layout>
          )
  }

}else{
        return(
          <Layout>
          <Container>

          { <h3>No Video Info</h3> }
          
          </Container>
          </Layout>
          )
}

 
}
//if we make our own controls 
// { appsContext.state.videoPlayer.playing ? (
//                 <Button variant="dark" href="#!" size="md" onClick={handlePause}>
//                         <FontAwesomeIcon
//                           icon={faPause}
//                           size="lg"
//                           sx={{
//                             marginRight: '0.5rem'
//                           }}
//                         />
//                       </Button>
// ):(
//                       <Button variant="dark" href="#!" size="md" onClick={handlePlay}>
//                         <FontAwesomeIcon
//                           icon={faPlay}
//                           size="lg"
//                           sx={{
//                             marginRight: '0.5rem'
//                           }}
//                         />
//                       </Button>
//             )   
// }

// <p>
//           Query String:<br />
//           ?setId={ location.state.assetObj.realSetId }&setDate={ location.state.assetObj.setDate }&setName={ location.state.assetObj.name }&assetName={ location.state.assetName }
//           </p>
// <Layout>
//     <SEO title="Celebrity Candids" />
//     <h1>Hi from the second page</h1>
//     <p>Celebrity Candids</p>
//     <Link to="/">Go back to the homepage</Link>
//   </Layout>

export default XeroApp